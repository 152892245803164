@mixin defaultTableBlockStyle() {
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
  border: 1px solid #DBE0EC;
  border-radius: 18px;

  display: grid;
  grid-template-rows: auto;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 24px;
  overflow: hidden;
  z-index: 2;
}

@mixin defaultTableCellStyle() {
  padding: 4px 8px;
  font-size: 14px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  background: #fff;
  border-left: 1px solid #DBE0EC;
  border-top: 1px solid #DBE0EC;
  z-index: 1;

  &.header {
    background: #EFF3FB;
    font-weight: 600;
    text-align: center !important;
    justify-content: center !important;

    &.tax {
      grid-column: span 2;
    }
  }

  &.no-left-border {
    border-left: none;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }
}

@mixin defaultTableTitleStyle() {
  font-size: 16px;
  color: #000;
  padding: 20px 0 20px 20px;
  min-height: 96px;

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 12px;

  > div:nth-of-type(1) {
    line-height: 0;
  }
}

@mixin defaultTablePriceStyle() {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 8px;

  padding: 20px 24px 20px 0;
  white-space: nowrap;
  text-align: right;

  > div:nth-of-type(1) {
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    color: #000;

    &.text-only {
      font-weight: 400;
      font-size: 14px;
    }
  }

  > div:nth-of-type(2) {
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    color: #707070;
  }
}

@mixin defaultIncludeButtonMixin() {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 8px;

  padding: 20px 24px 20px 0;
}

@mixin defaultHeaderCostCellMixin() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@mixin defaultTableFieldsStyle() {
  padding: 20px 0;
  display: grid;
  grid-gap: 12px;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-end;
  align-items: center;
  container-type: inline-size;

  .progress-container {
    line-height: 0;
    width: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .addition-service-fields-list .addition-service-fields-row {
    direction: rtl;

    .addition-service-field, .addition-service-field * {
      direction: ltr;
    }
  }
}