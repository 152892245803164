.addition-service-fields-container {
  container-type: inline-size;
  box-sizing: border-box;
}

.addition-service-fields-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 20px;
  width: 100%;

  .addition-service-fields-row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: min-content;
    align-items: flex-end;

    .addition-service-block {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: min-content;
      align-items: flex-end;

      @include mediaGen("block-size");

      .addition-service-field {
        @for $i from 1 through 12 {
          &.field-size-#{$i} {
            grid-column: span #{$i};
          }
        }

        .a-service-field-caption {
          color: #707070;
          font-family: Manrope, sans-serif;
          font-size: 11px;
          font-weight: 600;
          line-height: 110%;

          padding-bottom: 4px;

          width: 100%;

          &.direction-left {
            text-align: left !important;
          }

          &.direction-right {
            text-align: right !important;
          }

          &.direction-center {
            text-align: center !important;
          }
        }

        .MuiFormHelperText-root {
          margin-bottom: -26px !important;
        }

        .a-service-field-component {
          .a-service-field-checkbox .checkbox-label {
            font-family: Manrope, sans-serif !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 110% !important;
          }

          .a-service-field-number {
            .MuiInputBase-root {
              font-size: 14px !important;
              line-height: 14px !important;
              height: 56px;
              padding: 0 10px !important;
              color: black !important;
            }

            &.small .MuiInputBase-root {
              height: 40px;
            }

            input {
              padding: 8.5px 4px !important;
            }

            &.direction-left input {
              text-align: left !important;
            }

            &.direction-right input {
              text-align: right !important;
            }

            &.direction-center input {
              text-align: center !important;
            }
          }

          .a-service-field-text-impl {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            > span:nth-of-type(1) {
              text-align: left;
            }

            > span:nth-of-type(2) {
              line-height: 0;
              margin-left: 4px;
            }
          }

          .a-service-field-selector {
            color: black !important;

            .MuiSelect-select, .MuiMenuItem-root {
              font-size: 14px !important;
              font-family: Manrope, sans-serif !important;
              font-weight: 600 !important;
              padding-top: 11px !important;
              padding-bottom: 12px !important;
            }

            &.medium {
              height: 56px;
            }

            &.small {
              height: 40px;

              .MuiSelect-select, .MuiMenuItem-root {
                padding-top: 4px !important;
                padding-bottom: 4px !important;
              }
            }

            .a-service-field-selector-loader {
              padding-top: 4px;
              margin-right: 22px;

              &.small {
                padding-top: 2px;
                margin-right: 18px;
              }
            }
          }

          .a-service-field-loc-search {
            &.small {
              height: 40px;
            }

            input {
              font-size: 14px !important;
              color: #000 !important;
              padding: 9px 4px 9px 5px !important;
            }

            .a-service-field-loc-search-loader {
              margin-right: 12px;
              line-height: 0;

              &.small {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .MuiInputLabel-root, fieldset {
    font-size: 1.01rem;
  }
}

.a-service-field-loc-search-option {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  padding: 8px 16px;
  margin: 4px 0;
  cursor: pointer;
  transition: 0.25s background-color;

  &:hover, &.checked {
    background-color: #EFF3FB;
    border-radius: 4px;
  }

  .option-label {
    font-weight: 800 !important;
    font-size: 13px !important;
    line-height: 15px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .option-description {
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 13px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}

.a-service-field-selector-option--root {
  margin-bottom: 4px !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.a-service-field-selector-option {
  color: black !important;
  font-size: 14px !important;
}

.a-service-field-selector-option.with-description {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 3px;
  padding: 1px 4px;

  div.option-label {
    font-weight: 800;
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div.option-description {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.medium {

  }

  &.small {
    grid-gap: 2px !important;
    padding: 2px 4px 1px;

    div.option-label {
      font-size: 12px;
    }

    div.option-description {
      font-size: 10px;
    }
  }
}

.is-prf-export {
  .a-service-field-caption {
    padding-bottom: 4px !important;
  }

  .a-service-field-component {
    padding-top: 5px!important;
  }

  .a-service-field-number {
    .MuiInputBase-root {
      font-size: 14px !important;
      line-height: 14px !important;
      height: 56px;
      padding: 0 10px !important;
      color: black !important;
      display: grid !important;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: 54px;
      align-items: center;
      box-sizing: border-box !important;

      > input {
        display: grid !important;
        align-items: center !important;
        padding: 0 !important;
        grid-template-columns: 1fr;
        line-height: 16px !important;
        height: auto !important;
        width: auto !important;
      }

      > button {
        padding: 0 !important;
        background-color: #EFF3FB !important;
        font-weight: 600 !important;
        display: grid !important;
        align-items: center !important;
        justify-content: center !important;
        line-height: 14px !important;

        &:hover {
          background: rgba(176, 183, 189, 0.08) !important;
        }
      }
    }

    &.small .MuiInputBase-root {
      height: 40px;
      grid-template-rows: 38px;
    }

    &.direction-left input {
      text-align: left !important;
    }

    &.direction-right input {
      text-align: right !important;
    }

    &.direction-center input {
      text-align: center !important;
    }
  }
}
