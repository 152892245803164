.a-service-template-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 24px;
  container-type: inline-size;

  div.a-service-template-group-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 24px;
  }

  .a-service-template-group-collapse-button {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-gap: 12px;
    font-size: 16px;
    color: #3460B4;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
    border: 1px solid transparent;
    border-radius: 18px;
    cursor: pointer;
    margin-bottom: 24px;
    background: #fff;
    transition: 0.25s border;

    &:hover {
      border: 1px solid #DBE0EC;
    }

    > div:nth-of-type(1) {
      line-height: 0;

      > svg {
        transform: rotate(0);
      }
    }

    > div:nth-of-type(2) {
      white-space: nowrap;
    }

    &.collapsed {
      > div:nth-of-type(1) > svg {
        transform: rotate(-180deg);
      }
    }
  }

  &-total-price {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-radius: 18px;
    background-color: rgba(239, 243, 251, 1);
    border: 1px solid rgba(219, 224, 236, 1);
    overflow: hidden;

    > div {
      height: 50px;
      display: flex;
      width: 100%;
      align-items: center;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      font-family: Manrope, sans-serif;
      padding: 0 24px;

      &.total {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        background: rgba(52, 96, 180, 1);
        height: 60px;
        color: #fff;
        grid-column: span 2;
        border-radius: 18px;
        padding: 0 56px;
        text-transform: uppercase;

        > div {
          font-size: 20px;
          font-weight: 800;
          text-align: left;
          display: flex;
          align-items: center;
        }

        > div:nth-of-type(2n) {
          text-align: right;
          justify-content: flex-end;
        }
      }
    }

    > div:nth-of-type(2n) {
      text-align: right;
      font-size: 16px;
      justify-content: flex-end;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    .button {
      height: 45px;
      padding: 0 28px 0 8px;
      border: 2px solid rgba(14, 171, 112, 1);
      background-color: rgba(14, 171, 112, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 8px;
      font-family: Manrope, sans-serif;
      font-size: 16px;
      font-weight: 600;
      transition: .25s transform, .25s background-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;

      @include noselect();

      &:hover {
        transform: scale(1.04);
      }

      &:active {
        transform: scale(1.12);
        background-color: rgb(14, 152, 97);
      }

      &.loading > div:first-of-type {
        opacity: 1;
      }

      > div:first-of-type {
        opacity: 0;
        line-height: 0;
        margin-right: 4px;
      }

      &.in-order {
        background: #fff;
        color: rgba(14, 171, 112, 1);

        &:hover {
          transform: scale(1);
        }

        &:active {
          transform: scale(1);
          background-color: #fff;
        }
      }
    }
  }
}